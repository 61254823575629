import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Une naissance" />
    <h3 className='underline-title'>Une naissance</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Il est né (pas le divin enfant!)<br />
        <span className="par-starting"></span>né de deux êtres aux sexes différents<br />
        <span className="par-starting"></span>né comme eux!<br />
        Et les anges du Ciel n'ont jamais empeché sa naissance<br />
        Et les communs des mortels ne devaient pas ne pas accepter sa naissance<br />
        Sa naissance comme la leur!<br />
        <span className="par-starting"></span>naissance ayant fait l'object d'état civil<br />
        <span className="par-starting"></span>enregistrée<br />
        <span className="par-starting"></span>recensée. <br />
      </p>
      <p>
        La naissance est donc comptée<br />
        Unité parmi des unités <br />
        La naissance est et sera.<br />
      </p>
      <p>
        Le soleil et l'air du Ciel favorisent sa croissance<br />
        Et les autres se chauffent du même soleil <br />
        <span className="par-starting"></span>et hument avec délice le même air<br />
        Son adolescence comme la leur!<br />
      </p>
      <p>
        Adulte il n'est ni soldat ni ancient combattant<br />
        Vivant il n'est ni illustre ni éclatant <br />
        Mort sans avoir monté au trône impérial<br />
        L'homme obscur parmi des hommes<br />
      </p>
      <p>
        Incroyable mais vrai<br />
        Il garde son nom et son identité enterés cependant!<br />
        Ni les anges ni les autres n'effacent son nom!<br />
      </p>
      <p>
        Il est mort ni de faim ni de soif<br />
        Il a vécu longtemps <br />
      </p>
      <p>
        Il est mort de mort comme leur mort!<br />
        <span className="par-starting"></span>mort de tout le monde!<br />
      </p>
      <p>
        Il n'a rien emporté lors de sa mort<br />
        <span className="par-starting"></span>pas même son canif de jeu préféré!<br />
      </p>
      <p>
        Il n'a eu ni faim ni soif<br />
        <span className="par-starting"></span>ni peur ni inquiétude<br />
        Il avait son Berger il l'a encore <br />
        Ce Berger là même qui fait paître <br />
        <span className="par-starting"></span>sans fusil<br />
        <span className="par-starting"></span>sans soldat<br />
        <span className="par-starting"></span>sans garde du corps<br />
      </p>
      <p>
        Simple baton<br />
        <span className="par-starting"></span>de direction<br />
      </p>
      <p>
        Merveilleux n'est-ce pas?<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
